import React from 'react'
import tw, {styled} from "twin.macro";
import { SectionHeading } from "src/ui-treact/components/misc/Headings.js";

const StyledDiv = styled.div`
width: 100%;
min-width: 320px;
text-align: center;
display: flex;
flex: 1;
.border-left {
    border-left: 5px solid #9aca38;
    padding-left:30px;
    padding-top:20px;
    padding-bottom:20px;
    text-align:left;
  }
`
const MainDiv = styled.div`
${tw`text-center mx-24 px-8 my-8  select-none border w-full`}
background-color:#fff;
border-color: #9bc93c
`;

const Heading = tw(SectionHeading)`my-4 py-4 font-black text-center text-2xl sm:text-xl lg:text-2xl  text-black font-normal font-semibold`;
const Description = tw.p`mt-4 text-left text-sm md:text-base lg:text-base leading-relaxed text-black font-normal`;
const TextOnly = ({ data, page}) => {
    // console.log('BuyCardsList', data)
    let finalData = data?.schema_json;
    let content = {
        heading: 'What’s in the Kit',
        description: '<strong>Original Soup Flavors:</strong> Vegetable, Minestrone, Vegetable Quinoa, Mushroom, Tomato.<br /><strong>— or—</strong><br /><strong>New Soup Flavors:</strong> Butternut Squash, Black Bean, Butternut Squash with Quinoa, Spinach with White Bean, Tomato.<br /><br /><strong>Your kit also includes: </strong>L-bar (two flavors: nut-based and choco crisp), Olives, Kale Crackers, Tea (Spearmint, Hybiscus), L-Drink, Supplements.<br /><br />',
      ...finalData,
    };
    return (
        <StyledDiv>
            <MainDiv>
                <Heading dangerouslySetInnerHTML={{ __html: content.heading }}></Heading>
                <Description dangerouslySetInnerHTML={{ __html: content.description }}></Description>
            </MainDiv>
        </StyledDiv>
    )
}

export default TextOnly
